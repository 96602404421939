import styled from 'styled-components'
import { Link } from 'gatsby'
import {
  spacing,
  radius,
  cssEasings,
  mqSizes,
} from '../../styles/utilities/variables'
import StyledHeading from '../Heading/style'
import StyledImage from '../MakeImage/style'

const StyledProductSerieCard = styled.div`
  height: 0;
  padding-bottom: 180%;
  position: relative;
  overflow: hidden;
  width: 100%;
  background: rgba(196, 196, 196, 0.1);
  border-radius: ${radius.small};
  transition: background-color 0.4s;

  & ${StyledHeading.h3} {
    padding: ${spacing.medium} 0 0 ${spacing.medium};
  }

  ${StyledImage} {
    position: absolute;
    top: 50%;

    width: 100%;
    max-width: 100%;

    transform: translateX(-60%) scale(2.5);
    transition: transform 0.5s ${cssEasings.easeOutCubic};
  }

  @media (min-width: ${mqSizes.mobile}px) {
    &:hover {
      background: rgba(196, 196, 196, 0.2);
      & ${StyledImage} {
        transform: translateX(-45%) scale(2.5);
      }
    }
  }
`

StyledProductSerieCard.Internal = styled(Link)``
StyledProductSerieCard.External = styled.a``
StyledProductSerieCard.Wrapper = styled.div``

export default StyledProductSerieCard
