import styled from 'styled-components'
import { Link } from 'gatsby'
import StyledText from '../Text/style'
import { colors, radius, mq, spacing } from '../../styles/utilities/variables'
import StyledImage from '../MakeImage/style'
import StyledHeading from '../Heading/style'

const fullSize = `
  width: 100%;
  height: 100%;
  max-width: 100%;
`

const positionVertical = {
  top: 'start',
  middle: 'center',
  bottom: 'end',
}

const positionHorizontal = {
  left: 'start',
  center: 'center',
}

const StyledSpot = styled.div`
  background: transparent;
  border-radius: ${radius.small};
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  overflow: hidden;
  ${fullSize}

  & ${StyledImage} {
    z-index: 1;

    img {
      object-position: ${props => (props.position ? props.position : 'center')};
    }
  }
`

StyledSpot.Overlay = styled.div`
  position: absolute;
  z-index: 2;
  background: rgba(0, 0, 0, 0.3);
  ${fullSize}
`

StyledSpot.BackgroundColor = styled.div`
  position: absolute;
  background-color: ${props =>
    props.color ? colors[props.color] : colors.white};
  ${fullSize}
`

StyledSpot.Internal = styled(Link)`
  position: relative;
  ${fullSize}
  padding-bottom: 35rem;
`
StyledSpot.External = styled.a`
  position: relative;
  ${fullSize}
  padding-bottom: 35rem;
`
StyledSpot.Wrapper = styled.div`
  position: relative;
  ${fullSize}
  padding-bottom: 35rem;
`

StyledSpot.Body = styled.div`
  padding: ${props =>
    positionVertical[props.textPositionVertical] === positionVertical.bottom
      ? `0 ${spacing.large} ${spacing.large}`
      : `${spacing.large} ${spacing.large} 0`};

  position: absolute;
  z-index: 3;
  ${fullSize}

  display: flex;
  flex-direction: column;
  align-items: ${props => positionHorizontal[props.textPostionHorizontal]};
  justify-content: ${props => positionVertical[props.textPositionVertical]};

  @media ${mq.mobile} {
    padding: ${spacing.large} ${spacing.base} 0;
  }

  & ${StyledHeading.h2} {
    margin-bottom: ${spacing.small};
    color: ${props =>
      props.fontColor === 'dark' ? colors.primaryDarkBlue : colors.white};
  }

  & ${StyledText} {
    margin-bottom: ${spacing.base};
    color: ${props =>
      props.fontColor === 'dark' ? colors.primaryDarkBlue : colors.white};
  }
`

export default StyledSpot
