import React from 'react'
import StyledEmbed from './style'
import { useIntersectionObserver } from '../../hooks/useIntersectionObserver'
import { useRef, useState } from 'react'
const Embed = ({ children, critical }) => {
  const embedWrapperRef = useRef(null)
  const [isVisible, setVisible] = useState(!!critical)
  /**
   * Handle in view
   */
  useIntersectionObserver({
    target: embedWrapperRef,
    onIntersect: () => {
      setVisible(true)
    },
  })
  const innerData = isVisible
    ? {
        dangerouslySetInnerHTML: {
          __html: children,
        },
      }
    : null
  return <StyledEmbed ref={embedWrapperRef} {...innerData}></StyledEmbed>
}
export { Embed }
