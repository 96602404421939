import React from 'react'
import PropTypes from 'prop-types'
import { getUrl } from '../../helpers/url'
import StyledJumbotron from './style'
import { Container } from '../Container'
import { isInternal } from '../../helpers/link'
import { MakeImage } from '../MakeImage'

const layouts = ['right', 'left']

const Jumbotron = props => {
  const { heading, text, layout, link, image } = props
  const bgImage = image && image.makeImage ? image.makeImage : null

  const Component =
    link && link.url
      ? isInternal(getUrl(link.url))
        ? StyledJumbotron.Internal
        : StyledJumbotron.External
      : StyledJumbotron.External

  return (
    <>
      <StyledJumbotron>
        {bgImage && <MakeImage makeImage={bgImage} coverBackground />}
        {heading && (
          <Container>
            <StyledJumbotron.Card layout={layout}>
              <StyledJumbotron.Heading>{heading}</StyledJumbotron.Heading>
              {text && <StyledJumbotron.Text>{text}</StyledJumbotron.Text>}

              {link && link.url && link.title && (
                <Component
                  to={
                    link.url && isInternal(getUrl(link.url))
                      ? getUrl(link.url)
                      : null
                  }
                  href={
                    link.url && !isInternal(getUrl(link.url))
                      ? getUrl(link.url)
                      : null
                  }
                >
                  {link.title}
                </Component>
              )}
            </StyledJumbotron.Card>
          </Container>
        )}
      </StyledJumbotron>
      {heading && (
        <StyledJumbotron.MobileCard>
          <StyledJumbotron.Heading>{heading}</StyledJumbotron.Heading>
          {text && <StyledJumbotron.Text>{text}</StyledJumbotron.Text>}

          {link && link.url && link.title && (
            <Component
              to={
                link.url && isInternal(getUrl(link.url))
                  ? getUrl(link.url)
                  : null
              }
              href={
                link.url && !isInternal(getUrl(link.url))
                  ? getUrl(link.url)
                  : null
              }
            >
              {link.title}
            </Component>
          )}
        </StyledJumbotron.MobileCard>
      )}
    </>
  )
}

Jumbotron.propTypes = {
  layout: PropTypes.oneOf(layouts),
  heading: PropTypes.string,
  text: PropTypes.string,
  link: PropTypes.any,
  card: PropTypes.any,
}

export { Jumbotron }
