import styled from 'styled-components'
import { mq, spacing } from '../../styles/utilities/variables'
import StyledColumn from '../Column/style'
const StyledColumns = styled.div`
  display: flex;
  justify-content: flex-start;

  @media ${mq.mobile} {
    flex-direction: column;
  }

  & > * {
    margin-right: ${spacing.large};

    ${StyledColumn} {
      margin-right: ${spacing.medium};
    }

    @media ${mq.mobile} {
      margin-right: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: ${spacing.small};

      &:last-child {
        margin-bottom: 0;
      }

      ${StyledColumn} {
        margin-right: 0;
      }
    }

    &:last-child {
      margin-right: 0;
      margin-bottom: 0;
    }
  }
`

export default StyledColumns
