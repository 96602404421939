import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import PropTypes from 'prop-types'
import { sendForm } from '../../helpers/api'
import { handleGravityFormsValidationErrors } from '../../helpers/form'
import { useFormQuery } from './hook'
import StyledFormBlock from './style'
import { Button } from '../../components/Button'
import { COLORS, SPACING } from '../../helpers/constants'
import { Divider } from '../../components/Divider'
import { Heading } from '../../components/Heading'
import { Text } from '../../components/Text'
import { Content } from '../../components/Content'
import { Form } from '../../components/Form'
import { FieldsBuilder } from './FieldsBuilder'
import { Loader } from '../../components/Loader'
import { Icon } from '../../components/Icon'

const FormBlock = props => {
  const { formId } = props
  const formData = useFormQuery(formId)

  const {
    apiURL,
    title,
    description,
    formFields,
    button: { text: submitText },
  } = formData

  const {
    register,
    handleSubmit,
    errors,
    setError,
    control,
    // setValue,
    // triggerValidation,
    // formState,
  } = useForm()

  const [generalError, setGeneralError] = useState('')
  const [formLoading, setFormLoading] = useState(false)
  const [confirmationMessage, setConfirmationMessage] = useState('')

  // const [debug, setDebug] = useState(null)

  const onSubmit = (values, e) => {
    if (!formLoading) {
      setFormLoading(true)
      sendForm(apiURL, values)
        .then(function(data) {
          // setDebug(JSON.stringify(data))
          if (data.status === 'success' || data.is_valid) {
            if (data.confirmation_message) {
              setConfirmationMessage(data.confirmation_message)
              e.target.reset() //simple solution
            }
          } else if (data.status === 'gravityFormErrors') {
            // Pass messages to handle that sets react-hook-form errors
            handleGravityFormsValidationErrors(
              data.validation_messages,
              setError
            )
          } else if (data.status === 'gravityFormErrors' || !data.is_valid) {
            handleGravityFormsValidationErrors(
              data.validation_messages,
              setError
            )
          } else {
            // Seemed to be an unknown issue
            setGeneralError('unknownError')
          }
        })
        .finally(() => {
          setFormLoading(false)
        })
    }
  }

  return (
    <StyledFormBlock>
      <Content>
        <Heading size={3} type={'h3'}>
          {title}
        </Heading>
        {description && <Text>{description}</Text>}
      </Content>

      <Form onSubmit={handleSubmit(onSubmit)}>
        <FieldsBuilder
          formFields={formFields}
          register={register}
          control={control}
          errors={errors}
        />

        <Divider size={SPACING.LARGE} line={COLORS.ADAPTED_LIGHT_BLUE} />
        <Form.Group>
          <StyledFormBlock.Footer>
            {formLoading && <Loader />}
            <Button large textColor={COLORS.SECONDARY_LIGHT_BLUE}>
              {submitText}
            </Button>
          </StyledFormBlock.Footer>

          <Content>
            {generalError && (
              <>
                <Divider size={SPACING.SMALL} />
                <StyledFormBlock.Error>{generalError}</StyledFormBlock.Error>
              </>
            )}
            {confirmationMessage && (
              <>
                <Divider size={SPACING.SMALL} />
                <StyledFormBlock.Confirmation>
                  <Icon icon={'check_circle_outline'} />
                  <StyledFormBlock.ConfirmationInner
                    dangerouslySetInnerHTML={{ __html: confirmationMessage }}
                  />
                </StyledFormBlock.Confirmation>
              </>
            )}

            {/* <Text>
              Debug:
              <br />
              {debug}
            </Text> */}
          </Content>
        </Form.Group>
      </Form>
    </StyledFormBlock>
  )
}

FormBlock.propTypes = {
  formId: PropTypes.string,
}

export { FormBlock }
