import styled from 'styled-components'
import { spacing, colors } from '../../styles/utilities/variables'
import StyledHeading from '../../components/Heading/style'
import StyledIcon from '../../components/Icon/style'

const StyledMagazineBlock = styled.div``

StyledMagazineBlock.Heading = styled.div`
  padding-bottom: ${spacing.base};
  margin-bottom: ${spacing.xLarge};
  border-bottom: 1px solid ${colors.adaptedLightBlue};
  text-transform: uppercase;
  display: flex;
  justify-content: space-between;

  & ${StyledHeading.h5} {
    color: ${colors.primaryRed};
    margin-left: ${spacing.base};
  }

  & ${StyledIcon} {
    margin-bottom: 3px;
  }
`

export default StyledMagazineBlock
