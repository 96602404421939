import React, { useState, useEffect } from 'react'
import StyledVideoPlayerControls from './style'
import { AudioPlay, AudioMute, VideoPlay, VideoPause } from '../Svg'

const VideoPlayerControls = props => {
  const { videoControlFields = {}, onChange } = props
  const { isPlayerMuted, isVideoPlaying } = videoControlFields

  function handleOnChange(field, value) {
    onChange(field, !value)
  }

  return (
    <StyledVideoPlayerControls>
      {isVideoPlaying ? (
        <StyledVideoPlayerControls.VideoPause
          onClick={() => handleOnChange('isVideoPlaying', isVideoPlaying)}
        >
          <VideoPause />
        </StyledVideoPlayerControls.VideoPause>
      ) : (
        <StyledVideoPlayerControls.VideoPlay
          onClick={() => handleOnChange('isVideoPlaying', isVideoPlaying)}
        >
          <VideoPlay />
        </StyledVideoPlayerControls.VideoPlay>
      )}
      {isPlayerMuted ? (
        <StyledVideoPlayerControls.AudioMute
          onClick={() => handleOnChange('isPlayerMuted', isPlayerMuted)}
        >
          <AudioMute />
        </StyledVideoPlayerControls.AudioMute>
      ) : (
        <StyledVideoPlayerControls.AudioPlay
          onClick={() => handleOnChange('isPlayerMuted', isPlayerMuted)}
        >
          <AudioPlay />
        </StyledVideoPlayerControls.AudioPlay>
      )}
    </StyledVideoPlayerControls>
  )
}

export default VideoPlayerControls
