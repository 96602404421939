import React from 'react'
import PropTypes from 'prop-types'
import StyledMagazineBlock from './style'
import { Grid } from '../../components/Grid'
import { ArticleCard } from '../../components/ArticleCard'
import { SectionHeader } from '../../components/SectionHeader'
import { Button } from '../../components/Button'
import { getUrl } from '../../helpers/url'

const MagazineBlock = props => {
  const { heading, link, posts } = props

  return (
    <StyledMagazineBlock>
      <StyledMagazineBlock.Heading>
        <SectionHeader text={heading} />
        {link && (
          <Button icon={'arrow_forward'} transparent href={getUrl(link.url)}>
            {link.title}
          </Button>
        )}
      </StyledMagazineBlock.Heading>
      <Grid col={2}>
        {posts.map((post, index) => {
          return (
            <ArticleCard key={index} post={post} layout={'vertical'} border />
          )
        })}
      </Grid>
    </StyledMagazineBlock>
  )
}

MagazineBlock.propTypes = {
  posts: PropTypes.any,
}

export { MagazineBlock }
