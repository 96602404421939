import React from 'react'
import StyledProductNavigation from './style'
import { HasContent } from '../../views/MegaMenu/Content'
import { useMegaMenuQuery } from '../../views/MegaMenu/hook'

const ProductNavigation = () => {
  const query = useMegaMenuQuery()
  return (
    <StyledProductNavigation>
      <HasContent
        content={query[0].contentColumns}
        showContent={true}
        isCurrentPage
      />
    </StyledProductNavigation>
  )
}

export { ProductNavigation }
