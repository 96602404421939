import React from 'react'
import PropTypes from 'prop-types'
import StyledFAQSection from './style'
import { Heading } from '../../components/Heading'
import { FAQ } from '../../components/FAQ'
import { Content } from '../../components/Content'

const FAQSection = props => {
  const { questions, title } = props
  const FAQ_Schema = {
    '@context': 'https://schema.org',
    '@type': 'FAQPage',
    mainEntity: questions.map(item => {
      return {
        '@type': 'Question',
        name: item.title,
        acceptedAnswer: {
          '@type': 'Answer',
          text: item.acfAttributes.summary,
        },
      }
    }),
  }

  return (
    <>
      {FAQ_Schema && (
        <script
          type='application/ld+json'
          dangerouslySetInnerHTML={{ __html: JSON.stringify(FAQ_Schema) }}
        />
      )}
      <StyledFAQSection>
        <Content>
          <Heading size={3} type={'h3'}>
            {title}
          </Heading>
          {questions.map((item, index) => {
            return (
              <FAQ
                key={index}
                id={title + -+index}
                question={item.title}
                answer={item.acfAttributes.summary}
                link={item.uri || item.link}
              />
            )
          })}
        </Content>
      </StyledFAQSection>
    </>
  )
}

FAQSection.propTypes = {
  title: PropTypes.string,
  questions: PropTypes.array,
}

export { FAQSection }
