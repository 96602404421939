import React from 'react'
import { StyledImageWithText } from './style'
import { Heading } from '../../components/Heading'
import { Content } from '../../components/Content'
import { Image } from '../../components/Image'
import { Button } from '../../components/Button'
import { getUrl } from '../../helpers/url'

export const ImageWithText = ({ title, image, alignImage, link, content }) => {
  const buttonLink = link?.url &&
    link?.title && {
      href: getUrl(link.url),
      target: link.target,
      large: true,
    }

  return (
    <StyledImageWithText data-align-image={alignImage}>
      {image && <Image {...image} />}
      <div className='content'>
        <Content>
          {title && <Heading>{title}</Heading>}
          {content && <div dangerouslySetInnerHTML={{ __html: content }} />}
        </Content>
        {buttonLink && <Button {...buttonLink}>{link?.title}</Button>}
      </div>
    </StyledImageWithText>
  )
}

export default ImageWithText
