import React, { useState } from 'react'
import PropTypes from 'prop-types'
import 'basiclightbox/dist/basicLightbox.min.css'
import { getUrl } from '../../helpers/url'
import StyledPageHero from './style'
import { Heading } from '../Heading'
import { Text } from '../Text'
import { Container } from '../Container'
import { Button } from '../Button'
import { COLORS } from '../../helpers/constants'
import { MakeImage } from '../MakeImage'
import VideoPlayerControls from '../VideoPlayerControls'

const PageHero = props => {
  const { image, heading, text, link, youtubeUrl } = props

  const [videoError, setVideoFailed] = useState(false)
  const [hasVideoLoaded, setHasVideoLoaded] = useState(false)
  const [videoControlFields, setVideoControlFields] = useState({
    isPlayerMuted: true,
    isVideoPlaying: true,
  })

  const hasTextContent = () => {
    if (!heading && !text && !link) {
      return false
    } else {
      return true
    }
  }

  const handleVideoError = () => {
    setVideoFailed(true)
  }

  const handleVideoLoaded = () => {
    setHasVideoLoaded(true)
    setVideoControlFields({ ...videoControlFields, isVideoPlaying: true })
  }

  const playerStyle = {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    width: '100%',
  }

  const handleOnChangeControls = (field, newValue) => {
    setVideoControlFields({ ...videoControlFields, [field]: newValue })
  }

  return (
    <StyledPageHero {...props} hasTextContent={hasTextContent()}>
      {youtubeUrl && (
        <VideoPlayerControls
          videoControlFields={videoControlFields}
          onChange={handleOnChangeControls}
        />
      )}
      {!youtubeUrl && image && <MakeImage makeImage={image} coverBackground />}
      <StyledPageHero.ReactPlayer
        muted={videoControlFields.isPlayerMuted}
        playing={videoControlFields.isVideoPlaying}
        onError={handleVideoError}
        onReady={handleVideoLoaded}
        url={youtubeUrl}
        style={playerStyle}
        controls={false}
        loop
        width='100%'
        height='100%'
        playsinline
        config={{
          youtube: {
            playerVars: { showinfo: 0 },
          },
        }}
      />
      {heading && <StyledPageHero.Overlay />}

      <StyledPageHero.Body>
        <Container>
          {heading && (
            <Heading size={1} type={'h1'}>
              {heading}
            </Heading>
          )}
          {text && <Text>{text}</Text>}
          {link && link.url && (
            <Button
              color={COLORS.SECONDARY_LIGHT_BLUE}
              textColor={COLORS.PRIMARY_DARK_BLUE}
              icon={'arrow_forward'}
              href={getUrl(link.url)}
            >
              {link.title}
            </Button>
          )}
        </Container>
      </StyledPageHero.Body>
    </StyledPageHero>
  )
}

PageHero.propTypes = {
  image: PropTypes.object,
  position: PropTypes.string,
  heading: PropTypes.string,
  text: PropTypes.string,
  link: PropTypes.object,
  youtubeUrl: PropTypes.string,
}

export { PageHero }
