import styled from 'styled-components'
import {
  colors,
  spacing,
  typography,
  mq,
} from '../../styles/utilities/variables'
import StyledHeading from '../Heading/style'
import StyledText from '../Text/style'
import StyledImage from '../MakeImage/style'
import React from 'react'
import ReactPlayer from 'react-player'

const StyledPageHero = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: ${props =>
    props.hasTextContent ? '36rem 0 4rem 0' : '48rem 0 4rem 0'};
  margin-top: -${spacing.xxxLarge};
  overflow: hidden;

  @media ${mq.tablet} {
    padding: ${props =>
      props.hasTextContent ? '24rem 0 4rem 0' : '38rem 0 4rem 0'};
    margin-top: -${spacing.xLarge};
  }

  @media ${mq.mobile} {
    margin-top: -${spacing.medium};
  }

  &:not(:first-child) {
    margin-top: 0;
  }

  ${StyledImage} {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;

    img {
      object-position: ${props => (props.position ? props.position : 'center')};
    }
  }
`

StyledPageHero.Overlay = styled.figure`
  background-color: ${colors.black};
  opacity: 0.15;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
`

StyledPageHero.Body = styled.div`
  padding: ${spacing.medium} 0;
  width: 100%;

  ${StyledHeading.h1} {
    margin-bottom: ${spacing.xSmall};
    color: ${colors.white};
    font-size: ${typography.sizes.xxLarge};

    @media ${mq.mobile} {
      font-size: ${typography.sizes.large};
    }
  }

  ${StyledText} {
    color: ${colors.white};
    font-size: ${typography.sizes.medium};
  }
`

StyledPageHero.LightboxTriggerVid = styled.button`
  background: rgba(255, 255, 255, 0.4);
  backdrop-filter: blur(10px);
  border-radius: 10px;
  font-size: 0;
  border: none;
  outline: none;
  position: absolute;
  left: 50%;
  top: ${props => (props.hasTextContent ? '40%' : '50%')};
  padding: 5px;
  transform: translateY(-50%) translateX(-50%);

  i {
    color: ${colors.primaryRed};
  }

  @media ${mq.tablet} {
    top: ${props => (props.hasTextContent ? '30%' : '50%')};
  }

  @media ${mq.mobile} {
    top: ${props => (props.hasTextContent ? '30%' : '50%')};
  }

  opacity: 0;
  transition: opacity 0.3s;
  .wf-active & {
    opacity: 1;
  }
`

StyledPageHero.ReactPlayer = styled(ReactPlayer)`
  video {
    object-fit: cover;
  }
`

export default StyledPageHero
