import styled from 'styled-components'
import { mq } from '../../styles/utilities/variables'
const StyledColumn = styled.div`
  align-self: ${props => props.align};
  width: ${props => props.width};
  position: ${props => props.position};

  &:only-child {
    width: 100%;
  }

  @media ${mq.mobile} {
    width: 100%;
    > * {
      width: 100%;
      height: auto;
      align-items: center;
    }
  }
`

export default StyledColumn
