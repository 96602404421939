import styled, { css } from 'styled-components'
import { Link } from 'gatsby'
import { spacing, typography, mq } from '../../styles/utilities/variables'

const StyledArticleCard = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  margin: ${spacing.xLarge} 0 0;
  color: #171717;
  ${props =>
    props.layout === 'vertical' &&
    css`
      color: #003145;
    `}

    ${props =>
      props.border &&
      css`
        border-bottom: 1px solid #d3e3ea;
        padding-bottom: ${spacing.xLarge};
        margin-top: 0;
      `}

  @media ${mq.mobile} {
    margin: ${spacing.small} 0 0;
  }
`
StyledArticleCard.Link = styled(Link)`
  display: flex;
  ${props =>
    props.layout === 'vertical' &&
    css`
      flex-direction: column;
      justify-content: space-between;
      width: 70%;
    `}
`

StyledArticleCard.TextWrapper = styled.div`
  display: flex;
  ${props =>
    props.layout === 'vertical' &&
    css`
      flex-direction: column;
      justify-content: space-between;
      width: 70%;
    `}
`
StyledArticleCard.Heading = styled.h4`
  font-style: normal;
  letter-spacing: ${props => (props.layout === 'horizontal' ? '0' : '0.03rem')};
  font-size: ${props =>
    props.layout === 'horizontal'
      ? typography.sizes.medium
      : typography.sizes.medium};
  line-height: ${props =>
    props.layout === 'horizontal' ? typography.sizes.large : '3.8rem'};
  width: ${props => (props.layout === 'horizontal' ? '80%' : '100%')};
  order: ${props => (props.layout === 'horizontal' ? '2' : '1')};
  font-weight: ${props => (props.layout === 'horizontal' ? 'bold' : 'normal')};
`

StyledArticleCard.ImageWrapper = styled.div`
  position: relative;
  height: 20rem;
  width: 30%;
  margin-left: ${spacing.large};
  min-width: 25rem;

  @media ${mq.mobile} {
    height: auto;
    min-width: auto;
    min-height: 12rem;
    margin-left: ${spacing.xSmall};
    width: 45%;
  }
`

export default StyledArticleCard
