import React from 'react'
import PropTypes from 'prop-types'
import StyledMagazineHeading from './style'
import { Heading } from '../Heading'

const MagazineHeading = props => {
  const { type, size, title, articleCard, layout, mostRead, bold } = props

  let hasFormatting = false
  let preHeader = null
  let mainHeader = null

  //title contains special formatting?
  if (title.includes('[') && title.includes(']')) {
    hasFormatting = true
  }
  if (title && hasFormatting) {
    //find & retain text within brackets
    preHeader = title
      .match(/\[(.*?)\]/)[1]
      .replace('[', '')
      .replace(']', ' ')
    //identify text after closing bracket
    mainHeader = title.substr(title.indexOf(']') + 1)
  }

  return (
    <StyledMagazineHeading
      articleCard={articleCard}
      mostRead={mostRead}
      layout={layout}
      bold={bold}
    >
      <Heading type={type} size={size}>
        {preHeader && <span>{preHeader}</span>}
        {mainHeader ? mainHeader : title}
      </Heading>
    </StyledMagazineHeading>
  )
}

MagazineHeading.propTypes = {
  type: PropTypes.string,
  size: PropTypes.number,
  title: PropTypes.string,
  articleCard: PropTypes.bool,
  mostRead: PropTypes.bool,
  bold: PropTypes.bool,
  layout: PropTypes.string,
}

export { MagazineHeading }
