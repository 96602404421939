import styled from 'styled-components'
import { spacing, colors } from '../../styles/utilities/variables'
import StyleIcon from '../../components/Icon/style'
import StyleLoader from '../../components/Loader/style'

const StyledFormBlock = styled.div``

StyledFormBlock.Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  ${StyleLoader} {
    margin: 0 ${spacing.base} 0 0;
  }
`

StyledFormBlock.Confirmation = styled.div`
  background: ${colors.secondaryLightBlue};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${spacing.medium};

  ${StyleIcon} {
    margin-right: ${spacing.xSmall};
  }
`

StyledFormBlock.ConfirmationInner = styled.div``

StyledFormBlock.Error = styled.div`
  background: ${colors.primaryRed};
  color: ${colors.white};
  padding: ${spacing.medium};
`

export default StyledFormBlock
