import React from 'react'
import { Form } from '../../components/Form'
import { Text } from '../../components/Text'
import { Divider } from '../../components/Divider'
import { SPACING } from '../../helpers/constants'

const FieldsBuilder = props => {
  const { formFields, register, control, errors } = props

  return formFields.map((field, index) => {
    const { id, label, description } = field

    const inputName = `input_${id}`

    return (
      <Form.Group key={id}>
        <Text>{label}</Text>
        <Field
          field={field}
          inputName={inputName}
          register={register}
          control={control}
          inValid={errors[inputName]}
        />
        {errors[inputName] && (
          <Form.Error>{errors[inputName].message}</Form.Error>
        )}
        {description && !errors[inputName] && (
          <Form.Description>{description}</Form.Description>
        )}
      </Form.Group>
    )
  })
}

const Field = props => {
  const { field, inputName, register, control, inValid } = props
  const { type, placeholder, maxLength, isRequired, choices } = field

  const options = JSON.parse(choices)

  switch (type) {
    // Start with the standard fields
    case 'text':
    case 'email':
    case 'number':
    case 'hidden':
    case 'phone':
      return (
        <Form.Input
          type={type}
          placeholder={placeholder}
          name={inputName}
          innerRef={register}
          maxLength={maxLength ? maxLength : undefined}
          inValid={inValid}
        />
      )

    case 'textarea':
      return (
        <Form.TextArea
          placeholder={placeholder}
          name={inputName}
          innerRef={register}
          maxLength={maxLength ? maxLength : undefined}
          inValid={inValid}
        ></Form.TextArea>
      )

    case 'select':
      return (
        <Form.Select
          placeholder={placeholder}
          name={inputName}
          options={options.map(option => {
            return { value: option.value, name: option.text }
          })}
          innerRef={register}
          inValid={inValid}
        />
      )

    case 'radio':
      return options.map((option, index) => {
        let optionInputName = `${inputName}.${index + 1}`
        return (
          <React.Fragment key={optionInputName}>
            <Form.Radio
              label={option.text}
              id={optionInputName}
              name={inputName}
              value={option.value}
              innerRef={register}
              inValid={inValid}
            />
            {index < options.length - 1 && <Divider size={SPACING.XX_SMALL} />}
          </React.Fragment>
        )
      })

    case 'checkbox':
      return options.map((option, index) => {
        let optionInputName = `${inputName}_${index + 1}`
        return (
          <React.Fragment key={optionInputName}>
            <Form.Checkbox
              label={option.text}
              id={optionInputName}
              name={optionInputName}
              value={option.value}
              innerRef={register}
              inValid={inValid}
            />
            {index < options.length - 1 && <Divider size={SPACING.XX_SMALL} />}
          </React.Fragment>
        )
      })

    case 'date':
      return (
        <Form.Date
          placeholder={placeholder}
          name={inputName}
          register={register}
          isRequired={isRequired}
          control={control}
          inValid={inValid}
        />
      )

    default:
      return null
  }
}

export { FieldsBuilder }
