import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { AppContext } from '../../config/context'
import StyledProductSection from './style'
import { Grid } from '../../components/Grid'
import { ProductCard } from '../../components/ProductCard'

const ProductSection = props => {
  const { productIds } = props
  const [state] = useContext(AppContext)
  const { products } = state

  const matchingProducts = products.filter(product =>
    productIds.includes(String(product.productId))
  )

  return (
    <StyledProductSection>
      <Grid col={4} mobileCol={2}>
        {matchingProducts &&
          matchingProducts.length > 0 &&
          matchingProducts.map((product, index) => (
            <ProductCard key={product.id} product={product} />
          ))}
      </Grid>
    </StyledProductSection>
  )
}

ProductSection.propTypes = {
  productIds: PropTypes.array,
}

export { ProductSection }
