import React from 'react'
import PropTypes from 'prop-types'
import StyledProductSerieCard from './style'
import { isInternal } from '../../helpers/link'
import { Heading } from '../Heading'
import { MakeImage } from '../MakeImage'

const ProductSerieCard = props => {
  const { title, href, image } = props

  const Component = href
    ? isInternal(href)
      ? StyledProductSerieCard.Internal
      : StyledProductSerieCard.External
    : StyledProductSerieCard.Wrapper

  return (
    <Component
      to={href && isInternal(href) ? href : null}
      href={href && !isInternal(href) ? href : null}
    >
      <StyledProductSerieCard>
        <Heading size={3} type={'h3'}>
          {title}
        </Heading>
        {image && <MakeImage makeImage={image} />}
      </StyledProductSerieCard>
    </Component>
  )
}

ProductSerieCard.propTypes = {
  title: PropTypes.string,
  href: PropTypes.string,
  image: PropTypes.string,
}

export { ProductSerieCard }
