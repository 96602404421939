import React from 'react'
import styled from 'styled-components'
import { mq } from '../../styles/utilities/variables'

const Muted = props => (
  <span {...props} className={`material-icons md-light ${props.className}`}>
    volume_off
  </span>
)
const NotMuted = props => (
  <span {...props} className={`material-icons md-light ${props.className}`}>
    volume_up
  </span>
)

const StyledVideoPlayerControls = styled.ul`
  cursor: pointer;
  position: absolute;
  left: 0.5em;
  bottom: 2.8em;
  z-index: 1000;
  border-radius: 1000px;
  .material-icons.md-light {
    color: rgb(255 255 255 / 50%);
    font-size: 36px;
  }

  list-style-type: none;
  margin: 0;
  padding: 0;

  @media ${mq.custom} {
    height: 40vh;
    left: -1.5em;
    top: 0.8em;
  }
`

StyledVideoPlayerControls.VideoPlay = styled.li``

StyledVideoPlayerControls.VideoPause = styled.li``

StyledVideoPlayerControls.AudioPlay = styled.li``

StyledVideoPlayerControls.AudioMute = styled.li``

export default StyledVideoPlayerControls
