import React from 'react'
import PropTypes from 'prop-types'
import StyledMagazineCategoryHeading from './style'

const MagazineCategoryHeading = props => {
  const { children, categories, date, mostRead, alignCenter } = props
  if (children) {
    return (
      <StyledMagazineCategoryHeading {...props}>
        / {children}
      </StyledMagazineCategoryHeading>
    )
  } else if (categories && date) {
    let formattedDate =
      date.substring(2, 4) + date.substring(5, 7) + date.substring(8, 10)
    return (
      <StyledMagazineCategoryHeading date={date}>
        {categories.nodes.map((category, index) => ' ' + category.name)} /{' '}
        {formattedDate}
      </StyledMagazineCategoryHeading>
    )
  } else if (categories && !mostRead) {
    return (
      <StyledMagazineCategoryHeading alignCenter>
        <span>
          /{categories.nodes.map((category, index) => ' ' + category.name)}
        </span>
      </StyledMagazineCategoryHeading>
    )
  } else if (categories && mostRead) {
    return (
      <StyledMagazineCategoryHeading mostRead={mostRead}>
        {categories.nodes.map((category, index) => ' ' + category.name)}
      </StyledMagazineCategoryHeading>
    )
  } else return null
}

MagazineCategoryHeading.propTypes = {
  children: PropTypes.any,
  categories: PropTypes.object,
  mostRead: PropTypes.bool,
  alignCenter: PropTypes.bool,
  date: PropTypes.string,
}

export { MagazineCategoryHeading }
