import styled from 'styled-components'
import { colors, spacing } from '../../styles/utilities/variables'
import StyledIcon from '../Icon/style'
import StyledContent from '../Content/style'

const StyledFAQ = styled.div`
  padding-bottom: 2.4rem;
  &:not(:last-child) {
    border-bottom: 1px solid ${colors.secondaryLightBlue};
  }

  & ${StyledIcon} {
    margin-right: ${spacing.xxSmall};
    transform: ${props => (props.open ? 'rotate(180deg)' : 'rotate(0deg)')};
    transition: transform 0.3s ease;
  }

  & ${StyledContent} {
    padding-top: ${spacing.small};
  }
`

StyledFAQ.Button = styled.button`
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0;
  padding-top: ${spacing.medium};
  border: 0;
  outline: none;
  text-align: start;
`

export default StyledFAQ
