import styled from 'styled-components'
import { spacing, mq } from '../../styles/utilities/variables'
import StyledGrid from '../../components/Grid/style'
import StyledContainer from '../../components/Container/style'

const StyledSerieSpots = styled.div`
  @media ${mq.mobile} {
    overflow-x: scroll;
    cursor: grab;
  }

  ${StyledContainer} {
    @media ${mq.mobile} {
      padding: 0;
      margin: 0 ${spacing.medium};
    }
  }

  ${StyledGrid} {
    > * {
      @media ${mq.mobile} {
        width: 35vw;
      }
      @media ${mq.mobileSmall} {
        width: 55vw;
      }
    }
  }

  a {
    position: relative;
    display: flex;

    &:last-child {
      &:after {
        content: '';
        position: absolute;
        right: -${spacing.medium};
        width: ${spacing.medium};
        height: 100%;
      }
    }
  }
`

export default StyledSerieSpots
