import styled, { css } from 'styled-components'
import { Link } from 'gatsby'
import {
  spacing,
  colors,
  mq,
  typography,
} from '../../styles/utilities/variables'

const StyledJumbotron = styled.div`
  width: 100%;
  padding: ${spacing.xxLarge};
  height: 85vh;
  min-height: 53rem;
  box-sizing: border-box;
  position: relative;
  & > div {
    height: 100%;

    @media ${mq.mobile} {
      display: none;
    }
  }

  @media ${mq.tabletPortrait} {
    height: 40vh;
    min-height: auto;
  }

  @media ${mq.mobile} {
    height: 30rem;
    min-height: auto;
  }
`

StyledJumbotron.MobileCard = styled.div`
  display: none;
  width: 100%;
  background-color: ${colors.secondaryLightBlue};
  padding: ${spacing.large};

  @media ${mq.mobile} {
    display: block;
  }
`

StyledJumbotron.Card = styled.div`
  width: 40%;
  background-color: ${colors.secondaryLightBlue};
  padding: ${spacing.large};
  position: absolute;
  bottom: 0;
  ${props =>
    props.layout === 'left' &&
    css`
      left: 0;
    `}

  ${props =>
    props.layout === 'right' &&
    css`
      right: 0;
    `}
`

StyledJumbotron.Heading = styled.h3`
  margin-bottom: ${spacing.base};
  font-family: ${typography.primaryFont};
  color: ${colors.primaryDarkBlue};
`

StyledJumbotron.Text = styled.p`
  font-size: 1.6rem;
  line-height: 2.4rem;
`

StyledJumbotron.Internal = styled(Link)`
  border-bottom: solid 1px;
  font-size: 1.6rem;
  line-height: 2.4rem;
  color: ${colors.primaryDarkBlue};
`

StyledJumbotron.External = styled.a`
  border-bottom: solid 1px;
  font-size: 1.6rem;
  line-height: 2.4rem;
  color: ${colors.primaryDarkBlue};
`

export default StyledJumbotron
