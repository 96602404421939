import styled, { css } from 'styled-components'
import {
  spacing,
  typography,
  colors,
  mq,
} from '../../styles/utilities/variables'

const StyledMagazineCategoryHeading = styled.div`
  color: ${colors.primaryRed};
  text-transform: uppercase;
  letter-spacing: 0.04em;
  font-size: ${typography.sizes.base};
  font-family: ${typography.secondaryFont};
  line-height: 120%;
  order: 2;
  margin-bottom: ${spacing.base};

  /* span {
    width: 650px;
  } */

  ${props =>
    props.mostRead &&
    css`
      margin-bottom: ${spacing.xSmall};
    `}
  ${props =>
    props.date &&
    css`
      margin-bottom: 0;
    `}
  ${props =>
    props.alignCenter &&
    css`
      /* width: 650px !important; */
      margin-right: auto;
      margin-left: auto;
    `}

  @media ${mq.mobile} {
    font-size: 14px;
    margin-bottom: ${spacing.base};
    ${props =>
      props.mostRead &&
      css`
        margin-bottom: ${spacing.xxxSmall};
      `}
    ${props =>
      props.date &&
      css`
        margin-bottom: 0;
      `}
    ${props =>
      props.alignCenter &&
      css`
        margin-left: 0;
        width: auto !important;
      `}
  }
`

export default StyledMagazineCategoryHeading
