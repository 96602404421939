import React from 'react'
import PropTypes from 'prop-types'
import StyledArticleCard from './style'
import { getUrl } from '../../helpers/url'
import { MagazineCategoryHeading } from '../MagazineCategoryHeading'
import { MagazineHeading } from '../MagazineHeading'
import { MakeImage } from '../MakeImage'

const layouts = ['vertical', 'horizontal']
const ArticleCard = ({ post, layout, border }) => {
  const { title, categories, date, uri } = post

  const image =
    post && post.featuredImage && post.featuredImage.node
      ? post.featuredImage.node.makeImageSmall
      : null

  return (
    <StyledArticleCard.Link to={uri ? getUrl(uri) : ''}>
      <StyledArticleCard layout={layout} border={border}>
        <StyledArticleCard.TextWrapper layout={layout}>
          {categories && date && (
            <MagazineCategoryHeading date={date} categories={categories} />
          )}
          {title && (
            <MagazineHeading size={3} type={'h4'} title={title} articleCard />
          )}
        </StyledArticleCard.TextWrapper>
        {image && (
          <StyledArticleCard.ImageWrapper>
            <MakeImage makeImage={image} coverBackground />
          </StyledArticleCard.ImageWrapper>
        )}
      </StyledArticleCard>
    </StyledArticleCard.Link>
  )
}

ArticleCard.propTypes = {
  post: PropTypes.any,
  border: PropTypes.bool,
  layout: PropTypes.oneOf(layouts),
}

export { ArticleCard }
