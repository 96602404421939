import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import StyledFAQ from './style'
import { useHasMounted } from '../../hooks/useHasMounted'
import { ContentExpander } from '../ContentExpander'
import { Content } from '../Content'
import { Text } from '../Text'
import { Icon } from '../Icon'
import { TextLink } from '../TextLink'
import { useTranslation } from '../../hooks/useTranslation'

const FAQ = props => {
  const { question, answer, link, id } = props

  const hasMounted = useHasMounted()
  const [open, setOpen] = useState(false)
  const [localStorageChecked, setLocalStorageChecked] = useState(false)

  const getTranslation = useTranslation()

  useEffect(() => {
    if (hasMounted) {
      if (!localStorageChecked) {
        setOpen(false || localStorage.getItem(`${id}`))
        setLocalStorageChecked(true)
      } else {
        if (open) localStorage.setItem(`${id}`, open)
        else localStorage.removeItem(`${id}`)
      }
    }
  }, [open, hasMounted])

  return (
    <StyledFAQ open={open}>
      <StyledFAQ.Button onClick={() => setOpen(!open)}>
        {question}
        <Icon size={28} icon={'keyboard_arrow_down'} />
      </StyledFAQ.Button>
      <ContentExpander open={open} duration={0.35}>
        <Content>
          <Text>{answer}</Text>
          <TextLink
            to={link}
            text={getTranslation('readMoreUppercaseText')}
            underline
          />
        </Content>
      </ContentExpander>
    </StyledFAQ>
  )
}

FAQ.propTypes = {
  question: PropTypes.string,
  answer: PropTypes.string,
  link: PropTypes.string,
  id: PropTypes.string,
}

export { FAQ }
