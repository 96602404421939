import styled from 'styled-components'
import { typography, mq } from '../../styles/utilities/variables'

const StyledMagazineHeading = styled.div`
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: ${typography.primaryFont};
    line-height: ${props =>
      props.articleCard || props.mostRead ? '136%' : '115%'};
    letter-spacing: 0.03rem;
    width: ${props => (props.layout === 'horizontal' ? '80%' : '100%')};
    order: ${props => (props.layout === 'horizontal' ? '2' : '1')};
    font-weight: ${props =>
      props.layout === 'horizontal' || props.bold ? 'bold' : 'normal'};
    span {
      font-weight: normal;
    }
  }

  h1 {
    padding-right: 0 !important;
    padding-left: 0 !important;
    margin-right: auto;
    margin-left: auto;
  }

  h2 {
    @media ${mq.mobile} {
      font-size: ${typography.sizes.large};
    }
  }
  h4 {
    @media ${mq.mobile} {
      font-size: ${typography.sizes.base};
    }
  }
`

export default StyledMagazineHeading
