import React from 'react'
import PropTypes from 'prop-types'
import StyledSpot from './style'
import { Heading } from '../Heading'
import { Text } from '../Text'
import { Button } from '../Button'
import { isInternal } from '../../helpers/link'
import { getUrl } from '../../helpers/url'
import { MakeImage } from '../MakeImage'
import { Content } from '../Content'

const Spot = props => {
  const {
    children,
    heading,
    text,
    linkTitle,
    href,
    backgroundImage,
    backgroundColor,
    fontColor,
    overlay,
    position,
    textPostionHorizontal,
    textPositionVertical,
  } = props
  const parsedUrl = getUrl(href)
  const Component = parsedUrl
    ? isInternal(parsedUrl)
      ? StyledSpot.Internal
      : StyledSpot.External
    : StyledSpot.Wrapper

  return (
    <StyledSpot {...props}>
      <Component
        to={parsedUrl && isInternal(parsedUrl) ? parsedUrl : null}
        href={parsedUrl && !isInternal(parsedUrl) ? parsedUrl : null}
      >
        {overlay && <StyledSpot.Overlay />}
        {backgroundImage && (
          <MakeImage makeImage={backgroundImage} coverBackground />
        )}
        <StyledSpot.BackgroundColor color={backgroundColor} />
        <StyledSpot.Body
          fontColor={fontColor}
          textPostionHorizontal={textPostionHorizontal}
          textPositionVertical={textPositionVertical}
        >
          <Content>
            <Heading size={2}>{heading}</Heading>
            <Text>{text}</Text>
          </Content>
          <div>
            <Button icon={'arrow_forward'}>{linkTitle}</Button>
          </div>
          {children}
        </StyledSpot.Body>
      </Component>
    </StyledSpot>
  )
}

Spot.propTypes = {
  children: PropTypes.any,
  heading: PropTypes.string,
  text: PropTypes.string,
  backgroundColor: PropTypes.string,
  backgroundImage: PropTypes.object,
  linkTitle: PropTypes.string,
  href: PropTypes.string,
  position: PropTypes.string,
}

export { Spot }
