import React from 'react'
import PropTypes from 'prop-types'
import StyledSerieSpots from './style'
import { Grid } from '../../components/Grid'
import { ProductSerieCard } from '../../components/ProductSerieCard'
import { Container } from '../../components/Container'
import { getUrl } from '../../helpers/url'

const SerieSpots = ({ series }) => {
  return (
    <StyledSerieSpots>
      <Container>
        <Grid col={4} tabletCol={4} mobileCol={4}>
          {series.map((item, index) => {
            const { title, uri, acfAttributes } = item
            const { serieSpotImage } = acfAttributes
            const image = serieSpotImage ? serieSpotImage.makeImage : null

            return (
              <ProductSerieCard
                key={index}
                image={image}
                title={title}
                href={getUrl(uri)}
              />
            )
          })}
        </Grid>
      </Container>
    </StyledSerieSpots>
  )
}

SerieSpots.propTypes = {
  title: PropTypes.string,
  series: PropTypes.array,
}

export { SerieSpots }
