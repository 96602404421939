import React from 'react'
import { Button } from '../../components/Button'
import { getUrl } from '../../helpers/url'
import { StyledCtaButton } from './style'

export const CtaButton = ({
  ctaButtonSize,
  ctaButtonShowArrow,
  ctaButtonLink,
}) => {
  const buttonLink = ctaButtonLink?.url && {
    href: getUrl(ctaButtonLink.url),
    target: ctaButtonLink.target,
    large: ctaButtonSize === 'large',
    small: ctaButtonSize === 'small',
    icon: ctaButtonShowArrow && 'arrow_forward',
  }

  return buttonLink ? (
    <StyledCtaButton>
      <Button {...buttonLink}>{ctaButtonLink.title}</Button>
    </StyledCtaButton>
  ) : (
    ''
  )
}
export default CtaButton
