import styled from 'styled-components'
import { spacing, mqSizes } from '../../styles/utilities/variables'
import StyledImage from '../../components/Image/style'
import StyledContent from '../../components/Content/style'

export const StyledImageWithText = styled.div`
  display: grid;
  grid-template-columns: repeat(
    auto-fit,
    minmax(${mqSizes.tabletPortrait / 2}px, 1fr)
  );
  grid-auto-flow: dense;
  gap: clamp(${spacing.small}, 5vw, ${spacing.xxxLarge});
  align-items: center;
  margin-block: ${spacing.large};

  &:first-child {
    margin-top: 0;
  }

  &[data-align-image='right'] ${StyledImage} {
    grid-column-end: -1;
  }

  ${StyledImage} {
    aspect-ratio: 1/1;
    min-height: 100%;

    img {
      object-fit: cover;
    }
  }

  .content {
    padding-block: ${spacing.base};

    ${StyledContent} {
      font-size: 1.8rem;
      margin-bottom: ${spacing.large};
    }
  }
`
