import React from 'react'
import Img from 'gatsby-image'
import { getImageUrl } from '../../helpers/image'

import StyledImage from './style'

const Image = props => {
  const alt = props.altText ? props.altText : props.alt
  const caption = props.caption ? props.caption : null
  const align = props.imageAlign ? props.imageAlign : null
  const widthPercentage = props.widthPercentage
    ? `${props.widthPercentage}%`
    : '100%'

  if (props.imageFile && props.imageFile.childImageSharp) {
    return (
      <StyledImage align={align} width={widthPercentage}>
        <Img
          style={{ width: widthPercentage }}
          loading={'lazy'}
          alt={alt}
          fluid={props.imageFile.childImageSharp.fluid}
        />
        {caption && (
          <StyledImage.Caption
            width={widthPercentage}
            dangerouslySetInnerHTML={{ __html: caption }}
          />
        )}
      </StyledImage>
    )
  }

  return (
    <StyledImage align={align} width={widthPercentage}>
      <img src={getImageUrl(props)} alt={alt} />
      {caption && (
        <StyledImage.Caption
          width={widthPercentage}
          dangerouslySetInnerHTML={{ __html: caption }}
        />
      )}
    </StyledImage>
  )
}

export { Image }
