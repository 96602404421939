import styled from 'styled-components'
import { typography, spacing } from '../../styles/utilities/variables'

const StyledImage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: ${props =>
    (props.align === 'right' && 'flex-end') ||
    (props.align === 'center' && 'center') ||
    'flex-start'};

  width: 100%;

  > img {
    width: ${props => props.width};
    height: 100%;
  }
`

StyledImage.Caption = styled.p`
  font-size: ${typography.sizes.small};
  text-align: left;
  margin-top: ${spacing.xSmall};
  width: ${props => props.width};
`

export default StyledImage
