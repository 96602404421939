import styled from 'styled-components'
import { colors, spacing } from '../../styles/utilities/variables'
import StyledHeading from '../../components/Heading/style'

const StyledFAQSection = styled.div`
  display: flex;
  flex-direction: column;

  max-width: 800px; /* this we might remove */
  margin: 0 auto; /* this we might remove */

  & ${StyledHeading.h3} {
    border-bottom: 1px solid ${colors.secondaryLightBlue};
    padding-bottom: ${spacing.small};
    width: 100%;
  }
`

export default StyledFAQSection
