import styled from 'styled-components'
import { colors, spacing, mq } from '../../styles/utilities/variables'
import StyledHasContent from '../../views/MegaMenu/Content/style'
import StyledGrid from '../../components/Grid/style'
import StyledCol from '../../components/Col/style'

const StyledProductNavigation = styled.div`
  & ${StyledHasContent.List} {
    border-top: 1px solid ${colors.secondaryLightBlue};
  }

  & ${StyledGrid} {
    grid-template-columns: repeat(4, 1fr);

    @media ${mq.mobile} {
      display: flex;
      flex-direction: column;

      ${StyledCol} {
        margin-bottom: ${spacing.base};
      }
    }
  }
`

export default StyledProductNavigation
