import React from 'react'
import { SPACING, TEXT_SIZES, COLORS } from './constants'
import { parseVerticalAlign, logger } from './utilities'

import { Heading } from '../components/Heading'
import { Content } from '../components/Content'
import { Container } from '../components/Container'
import { Divider } from '../components/Divider'
import { Text } from '../components/Text'
import { Spot } from '../components/Spot'
import { FAQSection } from '../blocks/FAQSection'
import { FeatureGrid } from '../components/FeatureGrid'
import { PageHero } from '../components/PageHero'
import { Columns } from '../blocks/Columns'
import { Column } from '../blocks/Column'
import { IndentWrapper } from '../blocks/IndentWrapper'
import { SerieSpots } from '../blocks/SerieSpots'
import { ProductNavigation } from '../blocks/ProductNavigation'
import { SectionHeader } from '../components/SectionHeader'
import { Jumbotron } from '../components/Jumbotron'
import { FormBlock } from '../blocks/FormBlock'
import { MagazineBlock } from '../blocks/MagazineBlock'
import { MakeImage } from '../components/MakeImage'
import { Embed } from '../components/Embed'
import { ProductSection } from '../blocks/ProductSection'
import { ImageWithText } from '../blocks/ImageWithText'
import CtaButton from '../blocks/CtaButton/CtaButton'

/**
 * Block spacing
 */
const BLOCK_SPACING = {
  'core/heading': false,
  'core/paragraph': false,
  'acf/divider': false,
  'stella/indent-wrapper': SPACING.X_SMALL,
  'acf/jumbotron': false,
  //'someblock': false // to remove spacing
}

/**
 * WP Blocks Generator
 * @param {*} param
 */
export const WpBlocksGenerator = ({ blocks, post, container = true }) => {
  if (!blocks) return null
  if (container) logger(blocks)
  return (
    <>
      {blocks.map((block, key) => {
        const spacing =
          BLOCK_SPACING[block.name] !== undefined
            ? BLOCK_SPACING[block.name]
            : SPACING.MEDIUM

        return (
          <React.Fragment key={key}>
            <WpBlockParser block={block} post={post} container={container} />
            {key < blocks.length - 1 && spacing && <Divider size={spacing} />}
          </React.Fragment>
        )
      })}
    </>
  )
}

/**
 * WP Block parser
 * @param {*} param
 */
const WpBlockParser = ({ block, post, container = true }) => {
  // Exclude blocks that dont need container
  const hasContainer = ![
    'acf/page-hero',
    'acf/jumbotron',
    'acf/serie-spots',
  ].includes(block.name)

  if (container && hasContainer)
    return (
      <Container>
        <WpBlock block={block} post={post} />
      </Container>
    )
  return <WpBlock block={block} post={post} />
}

/**
 * WP Block
 * @param {*} param
 */
const WpBlock = ({ block, post }) => {
  // const isClient = typeof window === 'object'

  if (block.name === 'core/freeform') {
    return (
      <Content>
        <div
          dangerouslySetInnerHTML={{ __html: block.attributes.oldContent }}
        />
      </Content>
    )
  }

  if (block.name === 'acf/indent-wrapper') {
    const acf = block.acf

    return (
      <IndentWrapper col={parseInt(acf.indentwrapperIndentSize)}>
        {block.innerBlocks.map((innerBlock, key) => {
          return <WpBlock key={key} block={innerBlock} post={post} />
        })}
      </IndentWrapper>
    )
  }

  if (block.name === 'core/image') {
    // return <Image {...block.attributes} />
    return <MakeImage {...block.attributes} />
  }

  if (block.name === 'core/columns') {
    return (
      <Columns>
        {block.innerBlocks.map((innerBlock, key) => {
          return <WpBlock key={key} block={innerBlock} post={post} />
        })}
      </Columns>
    )
  }

  if (block.name === 'core/column') {
    return (
      <Column
        width={block.attributes.width ? block.attributes.width + '%' : '50%'}
        align={
          block.attributes.verticalAlignment
            ? parseVerticalAlign(block.attributes.verticalAlignment)
            : 'flex-start'
        }
      >
        <WpBlocksGenerator
          blocks={block.innerBlocks}
          container={false}
          post={post}
        />
      </Column>
    )
  }

  if (block.name === 'core/heading') {
    return (
      <Content>
        <Heading
          size={block.attributes.level}
          type={`h` + block.attributes.level}
          align={block.attributes.textAlign}
          color={block.attributes.textColor}
          anchor={block.attributes.anchor}
        >
          {block.attributes.content}
        </Heading>
      </Content>
    )
  }

  if (block.name === 'core/paragraph') {
    if (!block.attributes.content) return null
    const fontSize = block.attributes.fontSize
    const size =
      fontSize === 'large'
        ? TEXT_SIZES.LARGE
        : fontSize === 'medium'
        ? TEXT_SIZES.MEDIUM
        : fontSize === 'small'
        ? TEXT_SIZES.SMALL
        : TEXT_SIZES.BASE // Default size 18px
    return (
      <Content align={block.attributes.textAlign}>
        <Text size={size}>
          <span
            dangerouslySetInnerHTML={{ __html: block.attributes.content }}
          />
        </Text>
      </Content>
    )
  }

  /**
   * Core Embed
   */
  if (block.name === 'core/embed') {
    return <Embed>{block.attributes.oEmbed}</Embed>
  }

  if (block.name === 'acf/image-with-text') {
    const acf = block.acf
    return (
      <ImageWithText
        title={acf.title}
        alignImage={acf.alignImage}
        link={acf.link}
        content={acf.content}
        image={acf.image !== null ? acf.image.makeImage : null}
      />
    )
  }

  if (block.name === 'acf/cta-button') {
    const acf = block.acf
    return <CtaButton {...acf} />
  }

  if (block.name === 'acf/faq-section') {
    const acf = block.acf
    const faqPosts = acf.faqPosts ? acf.faqPosts : []
    return <FAQSection title={acf.faqTitle} questions={faqPosts} />
  }

  if (block.name === 'acf/spot') {
    const acf = block.acf

    return (
      <Spot
        heading={acf.spotHeading}
        text={acf.spotText}
        color={acf.spotColor}
        href={acf.spotLink.url}
        linkTitle={acf.spotLink.title}
        // image={acf.spotImage !== null ? acf.spotImage.mediaItemUrl : null}
        position={acf.spotImagePosition}
        backgroundImage={
          acf.spotBackgroundImage !== null
            ? acf.spotBackgroundImage.makeImage
            : null
        }
        backgroundColor={acf.spotBackgroundColor}
        fontColor={acf.spotFontColor}
        overlay={acf.spotOverlay}
      />
    )
  }

  if (block.name === 'acf/feature-grid') {
    const acf = block.acf
    return acf.featurePosts ? <FeatureGrid features={acf.featurePosts} /> : null
  }

  if (block.name === 'acf/divider') {
    const { dividerStyle, dividerSpacing } = block.acf

    const size =
      dividerSpacing === 'large'
        ? {
            desktop: SPACING.LARGE,
            tablet: SPACING.LARGE,
            mobile: SPACING.MEDIUM,
          }
        : dividerSpacing === 'small'
        ? {
            desktop: SPACING.SMALL,
            tablet: SPACING.SMALL,
            mobile: SPACING.SMALL,
          }
        : {
            desktop: SPACING.MEDIUM,
            tablet: SPACING.MEDIUM,
            mobile: SPACING.SMALL,
          }

    return (
      <Divider
        line={dividerStyle === 'line' ? COLORS.SECONDARY_LIGHT_BLUE : null}
        size={size}
      />
    )
  }

  if (block.name === 'acf/page-hero') {
    const acf = block.acf
    return (
      <PageHero
        // image={acf.pageheroImage.mediaItemUrl}
        image={
          acf.pageheroImage && acf.pageheroImage.makeImage
            ? acf.pageheroImage.makeImage
            : null
        }
        position={acf.pageheroImgPosition}
        heading={acf.pageheroHeading}
        text={acf.pageheroText}
        link={acf.pageheroLink}
        youtubeUrl={acf.pageheroYoutubeUrl}
      />
    )
  }

  if (block.name === 'acf/section-header') {
    const acf = block.acf
    return <SectionHeader text={acf.sectionHeading} />
  }

  if (block.name === 'acf/product-navigation') {
    return <ProductNavigation />
  }

  if (block.name === 'acf/serie-spots') {
    const series = block.acf && block.acf.seriePosts ? block.acf.seriePosts : []
    return <SerieSpots series={series} />
  }

  if (block.name === 'acf/jumbotron') {
    const acf = block.acf
    return (
      <Jumbotron
        text={acf.jumbotronText}
        image={acf.jumbotronImage}
        heading={acf.jumbotronHeading}
        layout={acf.jumbotronLayout}
        link={acf.jumbotronLink}
      />
    )
  }

  if (block.name === 'acf/form') {
    const { formId } = block.acf
    return <FormBlock formId={formId} />
  }

  if (block.name === 'acf/magazine') {
    const acf = block.acf
    const posts = acf.magazinePosts ? acf.magazinePosts : []
    const link = acf.magazineLink
    const heading = acf.magazineHeading
    return <MagazineBlock heading={heading} link={link} posts={posts} />
  }

  if (block.name === 'acf/product-section') {
    const acf = block.acf
    return acf.productSectionProducts ? (
      <ProductSection productIds={acf.productSectionProducts} />
    ) : null
  }

  console.log('Unknown block:', block)

  return null
}
